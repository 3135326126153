import db from '../axios-instances/firebase-db';
import * as objectUtil from '../util/objectUtil';

const loadSharedFactories = async (uid, token) => {
    const url = `/factory_shares.json?orderBy="share_with"&equalTo="${uid}"&auth=${token}`;
    const response = await db.get(url);
    const data = response.data;
    const factoryIds = objectUtil.mapObjectToArray(data, (key, share) => share.factory_id);
    const sharedFactories = {};
    for (let i = 0; i < factoryIds.length; i++) {
        const url = `/factories/${factoryIds[i]}.json?auth=${token}`;
        const response = await db.get(url);
        const factory = response.data;
        sharedFactories[factoryIds[i]] = new Factory(factoryIds[i], factory.userId, factory.name);
    }
    return sharedFactories;
};

class Factory {

    constructor(id, userId, name) {
        this.id = id;
        this.userId = userId;
        this.name = name;
    }

    static addFactory = async (userId, name, token) => {
        const newFactory = await db.post(`/factories.json?auth=${token}`, {userId: userId, name});
        return new Factory(newFactory.data.name, userId, name);
    };

    static loadFactories = async (uid, token) => {
        const url = `/factories.json?orderBy="userId"&equalTo="${uid}"&auth=${token}`;
        const factoriesResponse = await db.get(url);
        const fbFactories = factoriesResponse.data;
        const factories = {};
        for (let key in fbFactories) {
            if (!fbFactories.hasOwnProperty(key)) continue;
            const fbFactory = fbFactories[key];
            factories[key] = new Factory(key, fbFactory.userId, fbFactory.name);
        }
        const sharedFactories = await loadSharedFactories(uid, token);

        return {
            ...factories,
            ...sharedFactories
        };
    };

    shareFactory = async (uid, token, target) => {
        if (!this.userId === uid) throw new Error("Can only share your own factory.");
        const url = `/factory_shares.json?auth=${token}`;
        const data = {
            factory_id: this.id,
            share_with: target.trim().toLowerCase()
        };
        await db.post(url, data);
        return target.trim().toLowerCase();
    };

    usersSharedWith = async (token) => {
        const url = `/factory_shares.json?orderBy="factory_id"&equalTo="${this.id}"&auth=${token}`;
        const response = await db.get(url);
        const shares = response.data;
        return objectUtil.mapObjectToArray(shares, (key, share) => share.share_with);
    };

}

export default Factory;
