import React, {Component} from 'react';
import {Button, Col, Form, InputGroup, Row, Table} from 'react-bootstrap';
import {connect} from 'react-redux';

import Product from '../../models/Product';
import Building from '../../models/Building';
import Production from '../../models/Production';
import Factory from '../../models/Factory';
import * as objectUtil from '../../util/objectUtil';

class Productions extends Component {

    state = {
        products: [],
        buildings: [],
        factories: {},
        loading: true,
        form: {
            building: "",
            inputs: [],
            outputs: []
        },
        selectedFactory: "",
        productions: {}
    };

    componentDidMount() {
        this.initialLoad().then();
    }

    initialLoad = async () => {
        const factories = await Factory.loadFactories(this.props.uid, this.props.token);
        const factoryCount = Object.keys(factories).length;
        if (factoryCount === 0) {
            this.props.history.push('/manage-factories');
            return;
        }
        this.setState({
            factories: factories,
            selectedFactory: factories[Object.keys(factories)[0]].id
        }, async () => await this.loadProductions());
    };

    loadProductions = async () => {

        const productObj = await Product.loadProducts();
        const products = [];

        for (let key in productObj) {
            products.push(productObj[key]);
        }

        const buildingObject = await Building.loadBuildings();
        const buildings = [];
        for (let key in buildingObject) {
            buildings.push(buildingObject[key]);
        }

        const building = buildings[0];
        const inputs = [];
        for (let i = 0; i < building.inputs; i++) {
            inputs.push({
                product: products[0].id,
                amount: 0
            });
        }
        const outputs = [];
        for (let i = 0; i < building.outputs; i++) {
            outputs.push({
                product: products[0].id,
                amount: 0
            });
        }

        const factoryId = this.state.selectedFactory;

        const productions = await Production.loadProductions(factoryId, this.props.token);

        this.setState({
            products: products,
            buildings: buildings,
            loading: false,
            form: {
                building: buildings[0].id,
                inputs: inputs,
                outputs: outputs
            },
            productions: productions
        });
    };

    onBuildingSelected = event => {
        let found = false;
        let building = null;
        for (let i = 0; i < this.state.buildings.length && !found; i++) {
            if (this.state.buildings[i].id === event.target.value) {
                found = true;
                building = this.state.buildings[i];
            }
        }
        if (building === null) return;

        const updatedForm = {
            ...this.state.form
        };
        updatedForm.building = building.id;


        const inputs = [];
        for (let i = 0; i < building.inputs; i++) {
            inputs.push({
                product: this.state.products[0].id,
                amount: 0
            });
        }
        const outputs = [];
        for (let i = 0; i < building.outputs; i++) {
            outputs.push({
                product: this.state.products[0].id,
                amount: 0
            });
        }
        updatedForm.inputs = inputs;
        updatedForm.outputs = outputs;

        this.setState({
            form: updatedForm
        });
    };

    onInputProductUpdated = (event, inputIndex) => {
        const updatedForm = {
            ...this.state.form
        }
        const updatedInputs = [...updatedForm.inputs];
        updatedInputs[inputIndex].product = event.target.value;
        updatedForm.inputs = updatedInputs;
        this.setState({
            form: updatedForm
        });
    };

    onInputAmountUpdated = (event, inputIndex) => {
        const updatedForm = {
            ...this.state.form
        }
        const updatedInputs = [...updatedForm.inputs];
        updatedInputs[inputIndex].amount = isNaN(Number(event.target.value)) ? 0 : Math.max(Number(event.target.value), 0);
        updatedForm.inputs = updatedInputs;
        this.setState({
            form: updatedForm
        });
    };

    onOutputProductUpdated = (event, outputIndex) => {
        const updatedForm = {
            ...this.state.form
        }
        const updatedOutputs = [...updatedForm.outputs];
        updatedOutputs[outputIndex].product = event.target.value;
        updatedForm.outputs = updatedOutputs;
        this.setState({
            form: updatedForm
        });
    };

    onOutputAmountUpdated = (event, outputIndex) => {
        const updatedForm = {
            ...this.state.form
        }
        const updatedOutputs = [...updatedForm.outputs];
        updatedOutputs[outputIndex].amount = isNaN(Number(event.target.value)) ? 0 : Math.max(Number(event.target.value), 0);
        updatedForm.outputs = updatedOutputs;
        this.setState({
            form: updatedForm
        });
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        Production.addProduction(
            this.state.form.building,
            this.state.form.inputs,
            this.state.form.outputs,
            this.state.selectedFactory,
            this.props.token
        ).then(production => {
            const updatedProductions = {
                ...this.state.productions
            };
            const cloneObjectArray = arr => arr.map(obj => ({...obj}));
            updatedProductions[production.id] = new Production(
                production.id,
                production.building_id,
                cloneObjectArray(production.inputs),
                cloneObjectArray(production.outputs),
                production.isBuilt,
                this.state.selectedFactory
            );
            this.setState({productions: updatedProductions});
        })
    };

    onProductionDelete = (production) => {
        production.removeFromDatabase(this.props.token)
            .then(() => this.loadProductions());
    };

    onProductionToggleStatus = production => {
        production.toggleBuildStatus(this.props.token)
            .then(() => this.loadProductions());
    };

    onFactorySelected = async event => {
        this.setState({
            selectedFactory: event.target.value
        }, async () => await this.loadProductions())
    };

    render() {
        const buildForm = () => {
            if (this.state.loading) return (<h2>Loading...</h2>);
            const buildBuildingSelectRow = () => {
                const buildingOptions = this.state.buildings.map(building => (
                    <option key={building.id} value={building.id}>{building.name}</option>
                ));
                return (
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Building</Form.Label>
                                <Form.Control
                                    as={"select"}
                                    value={this.state.form.building}
                                    onChange={this.onBuildingSelected}>
                                    {buildingOptions}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                );
            };
            const buildInputOutputRow = () => {

                const buildProductInput = () => {
                    if (this.state.form.inputs.length <= 0) return null;
                    const buildInputs = () => (this.state.form.inputs.map((input, index) => {
                        const buildProductSelect = () => {
                            const productOptions = this.state.products.map(product => (
                                <option key={product.id} value={product.id}>{product.name}</option>
                            ));
                            return (
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Product</Form.Label>
                                        <Form.Control
                                            as={"select"}
                                            value={this.state.form.inputs[index].product}
                                            onChange={(event) => this.onInputProductUpdated(event, index)}>
                                            {productOptions}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            );
                        };
                        const buildAmountInput = () => {
                            return (
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={"number"}
                                                value={this.state.form.inputs[index].amount}
                                                onChange={(event) => this.onInputAmountUpdated(event, index)}/>
                                            <InputGroup.Append>
                                                <InputGroup.Text>/min</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            );
                        };
                        return (
                            <Row key={index}>
                                {buildProductSelect()}
                                {buildAmountInput()}
                            </Row>
                        );
                    }));
                    return (
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label>Inputs</Form.Label>
                            </Form.Group>
                            {buildInputs()}
                        </Col>
                    );
                };
                const buildProductOutput = () => {
                    if (this.state.form.outputs.length <= 0) return null;
                    const buildOutputs = () => (this.state.form.outputs.map((output, index) => {
                        const buildProductSelect = () => {
                            const productOptions = this.state.products.map(product => (
                                <option key={product.id} value={product.id}>{product.name}</option>
                            ));
                            return (
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Product</Form.Label>
                                        <Form.Control
                                            as={"select"}
                                            value={this.state.form.outputs[index].product}
                                            onChange={(event) => this.onOutputProductUpdated(event, index)}>
                                            {productOptions}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            );
                        };
                        const buildAmountInput = () => {
                            return (
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={"number"}
                                                value={this.state.form.outputs[index].amount}
                                                onChange={(event) => this.onOutputAmountUpdated(event, index)}/>
                                            <InputGroup.Append>
                                                <InputGroup.Text>/min</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            );
                        };
                        return (
                            <Row key={index}>
                                {buildProductSelect()}
                                {buildAmountInput()}
                            </Row>
                        );
                    }));
                    return (
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label>Outputs</Form.Label>
                            </Form.Group>
                            {buildOutputs()}
                        </Col>
                    );
                };
                return (
                    <Row>
                        {buildProductInput()}
                        {buildProductOutput()}
                    </Row>
                );
            };
            const buildSubmitButtonRow = () => {
                return (
                    <Row>
                        <Col>
                            <Button type={"submit"}>Submit</Button>
                        </Col>
                    </Row>
                );
            };
            return (
                <Form onSubmit={this.onFormSubmit}>
                    {buildBuildingSelectRow()}
                    {buildInputOutputRow()}
                    {buildSubmitButtonRow()}
                </Form>
            );
        };
        const buildProductionTable = () => {
            if (this.state.loading) return null;
            const buildTableHeading = () => {
                return (
                    <thead>
                    <tr>
                        <th>Building</th>
                        <th>Power Usage</th>
                        <th>Power Output</th>
                        <th>Inputs</th>
                        <th>Outputs</th>
                        <th>Factory</th>
                        <th>Delete</th>
                        <th>Toggle</th>
                    </tr>
                    </thead>
                );
            };
            const buildTableBody = () => {
                const buildProductionRows = () => {

                    const buildProductionRow = production => {
                        const joinProductionProductsToString = (productionProducts) => {
                            const formatProductionProduct = (productionProduct) => {
                                const getProductName = () => {
                                    let product = null;
                                    let found = false;
                                    for (let i = 0; i < this.state.products.length && !found; i++) {
                                        if (this.state.products[i].id === productionProduct.product) {
                                            found = true;
                                            product = this.state.products[i];
                                        }
                                    }
                                    return product === null ? "" : product.name;
                                };
                                return `${getProductName()}:${productionProduct.amount}/min`;
                            }
                            return productionProducts
                                .map(formatProductionProduct)
                                .join(' + ')
                        };

                        const findBuilding = () => {
                            let building = null;
                            let found = false;
                            for (let i = 0; i < this.state.buildings.length && !found; i++) {
                                if (this.state.buildings[i].id === production.building_id) {
                                    building = this.state.buildings[i];
                                    found = true;
                                }
                            }
                            return building;
                        };
                        const buildBuildingName = building => building ? building.name : "";
                        const formatWattage = (power) => `${power}MW`;
                        const buildPowerUsage = building => building ? formatWattage(building.powerInput) : "";
                        const buildPowerOutput = building => building ? formatWattage(building.powerOutput) : "";
                        const buildDeleteButton = () => {
                            return (
                                <Button
                                    variant={"danger"}
                                    type={"button"}
                                    onClick={() => this.onProductionDelete(production)}>X</Button>
                            );
                        };
                        const buildStatusToggle = () => {
                            return (
                                <Button
                                    variant={production.isBuilt ? "warning" : "success"}
                                    type={'button'}
                                    onClick={() => {
                                        this.onProductionToggleStatus(production)
                                    }}>
                                    {production.isBuilt ? "Mark Not Built" : "Mark Built"}
                                </Button>
                            );
                        };
                        const buildFactoryName = () => this.state.factories[production.factory_id].name;

                        const building = findBuilding();

                        const className = "table-" + (production.isBuilt ? "success" : "danger");

                        return ((
                            <tr key={production.id} className={className}>
                                <td>{buildBuildingName(building)}</td>
                                <td>{buildPowerUsage(building)}</td>
                                <td>{buildPowerOutput(building)}</td>
                                <td>{joinProductionProductsToString(production.inputs)}</td>
                                <td>{joinProductionProductsToString(production.outputs)}</td>
                                <td>{buildFactoryName()}</td>
                                <td>{buildDeleteButton(production)}</td>
                                <td>{buildStatusToggle()}</td>
                            </tr>
                        ));
                    };

                    const productionComparator = (product1, product2) => {
                        if (product1.isBuilt === product2.isBuilt) {
                            if (product1.building_id === product2.building_id) {
                                if (product1.outputs.length !== product2.outputs.length) {
                                    return product1.outputs.length - product2.outputs.length
                                }
                                let i = 0;
                                let foundMismatch = false;
                                while (i < product1.outputs.length && !foundMismatch) {
                                    if (product1.outputs[i].product !== product2.outputs[i].product) {
                                        foundMismatch = true;
                                    } else {
                                        i++;
                                    }
                                }
                                if (!foundMismatch) {
                                    return 0;
                                } else if (product1.outputs[i].product > product2.outputs[i].product) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            } else {
                                return product1.building_id.localeCompare(product2.building_id);
                            }
                        }
                        return product1.isBuilt ? 1 : -1;
                    };

                    return objectUtil.mapObjectToArray(this.state.productions, (key, production) => production)
                        .sort(productionComparator)
                        .map(buildProductionRow);
                };

                return (
                    <tbody>
                    {buildProductionRows()}
                    </tbody>
                );
            };
            return (
                <Table responsive striped hover bordered className={"mt-3"}>
                    {buildTableHeading()}
                    {buildTableBody()}
                </Table>
            );
        };
        const buildFactorySelect = () => {
            const buildSelect = () => {
                const buildOptions = () => {
                    const buildOption = (key, factory) => {
                        return <option key={key} value={key}>{factory.name}</option>;
                    };
                    return objectUtil.mapObjectToArray(this.state.factories, buildOption);
                };
                return (
                    <Form.Control
                        as={"select"}
                        value={this.state.selectedFactory}
                        onChange={this.onFactorySelected}>
                        {buildOptions()}
                    </Form.Control>
                );
            };
            return (
                <Form.Group>
                    <Form.Label>Select Factory</Form.Label>
                    {buildSelect()}
                </Form.Group>
            );
        };
        return (
            <Col>
                <h1>Productions</h1>
                {buildFactorySelect()}
                <h2>Add Production</h2>
                {buildForm()}
                {buildProductionTable()}
            </Col>
        );
    }
}

const mapStateToProps = state => {
    return {
        uid: state.auth.uid,
        token: state.auth.token
    }
};

export default connect(mapStateToProps)(Productions);
