import db from '../axios-instances/firebase-db';

export default class Production {

    constructor(id, building_id, inputs, outputs, isBuilt, factory_id) {
        this.id = id;
        this.building_id = building_id;
        this.inputs = inputs ? inputs : [];
        this.outputs = outputs ? outputs : [];
        this.isBuilt = !!isBuilt;
        this.factory_id = factory_id;
    }

    static addProduction = async (building_id, inputs, outputs, factoryId, token) => {
        const formData = {
            building_id: building_id,
            inputs: inputs,
            outputs: outputs,
            isBuilt: false,
            factory_id: factoryId
        };
        const response = await db.post(`/productions.json?auth=${token}`, formData);
        return new Production(response.data.name, building_id, inputs, outputs, false, factoryId);
    };

    static loadProductions = async (factoryId, token) => {
        const url = `/productions.json?orderBy="factory_id"&equalTo="${factoryId}"&auth=${token}`;
        const response = await db.get(url);
        const productionObj = response.data;
        const productions = {};
        for (let key in productionObj) {
            const production = productionObj[key];
            productions[key] = new Production(key, production.building_id, production.inputs, production.outputs, production.isBuilt, production.factory_id);
        }
        return productions;
    };

    removeFromDatabase = async token => {
        await db.delete(`/productions/${this.id}.json?auth=${token}`);
    };

    toggleBuildStatus = async token => {
        const formData = {
            isBuilt: !this.isBuilt
        };
        await db.patch(`/productions/${this.id}.json?auth=${token}`, formData);
    };

}
