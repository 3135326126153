import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import App from './App';
import authReducer from './store/reducers/auth/auth';
import settingsReducer from './store/reducers/settings/settings';

const rootReducer = combineReducers({
    auth: authReducer,
    settings: settingsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (<App/>);

const browserRouter = (<BrowserRouter>{app}</BrowserRouter>);

const provider = (<Provider store={store}>{browserRouter}</Provider>);

ReactDOM.render(provider, document.getElementById('root'));
