import React from 'react';

import Navigation from '../../components/Navigation/Navigation';
import ToggleExpandButton from '../../components/ToggleExpandButton/ToggleExpandButton';

const layout = props => (
    <React.Fragment>
        <Navigation/>
        {props.children}
        <ToggleExpandButton/>
    </React.Fragment>
);

export default layout;
