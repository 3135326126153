import React from 'react';
import {Col, Alert} from 'react-bootstrap';

const pleaseWait = (props) => (
    <Col>
        <Alert variant={"warning"}>
            <Alert.Heading>Please Wait</Alert.Heading>
        </Alert>
    </Col>
);

export default pleaseWait;
