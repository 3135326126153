import React, {Component} from 'react';
import {InputGroup, Button, Col, Form, Table, ListGroup} from 'react-bootstrap';
import {connect} from 'react-redux';

import Factory from '../../models/Factory';
import * as forms from '../../util/forms';
import * as validators from '../../util/validators';
import * as filters from '../../util/filters';
import * as objectUtil from '../../util/objectUtil';

class ManageFactories extends Component {

    state = {
        factories: {},
        addFactoryForm: {
            name: {
                label: "Name",
                value: '',
                touched: false,
                valid: false,
                validator: validators.requiredText
            }
        },
        addFactoryValid: false,
        shareFactoryForm: {
            factory: {
                value: "",
                valid: true,
                touched: true
            },
            email: {
                label: "Email",
                value: "",
                touched: false,
                valid: false,
                validator: validators.combineSeveralAnd(validators.requiredText, validators.isEmail),
                filter: filters.combineFilters(filters.trimText, filters.lowerCaseText, filters.removeSpaces)
            }
        },
        shareFactoryFormValid: false,
        factorySharedWith: []
    };

    componentDidMount() {
        this.loadFactories().then();
    }

    loadFactories = async () => {
        const factories = await Factory.loadFactories(this.props.uid, this.props.token);
        const updatedShareForm = {
            ...this.state.shareFactoryForm
        };
        const updatedShareSelect = {
            ...updatedShareForm.factory
        };
        updatedShareSelect.value = Object.keys(factories)[0];
        updatedShareForm.factory = updatedShareSelect;
        this.setState({
            factories: factories,
            shareFactoryForm: updatedShareForm
        });
        await this.getSharedWith(factories[Object.keys(factories)[0]]);
    };

    onAddFactoryUpdate = async event => {
        const newForm = forms.updateForm(event, 'name', this.state.addFactoryForm);
        this.setState({addFactoryForm: newForm.form, addFactoryValid: newForm.formValid});
    };

    onAddFactorySubmit = async event => {
        event.preventDefault();
        if (!this.state.addFactoryValid) return;
        const newFactory = await Factory.addFactory(this.props.uid, this.state.addFactoryForm.name.value, this.props.token);
        const updatedFactories = {
            ...this.state.factories
        };
        updatedFactories[newFactory.id] = {...newFactory};
        this.setState({factories: updatedFactories});
    };

    onShareFactoryChange = async (event, key) => {
        const updatedForm = forms.updateForm(event, key, this.state.shareFactoryForm);
        this.setState({
            shareFactoryForm: updatedForm.form,
            shareFactoryFormValid: updatedForm.formValid
        });
        if (key === "factory") await this.getSharedWith(this.state.factories[event.target.value]);
    };

    getSharedWith = async factory => {
        if (!factory) return;
        const emails = await factory.usersSharedWith(this.props.token);
        this.setState({factorySharedWith: emails});
    };

    shareFactory = async event => {
        event.preventDefault();
        if (!this.state.shareFactoryFormValid) return;
        const factory = this.state.factories[this.state.shareFactoryForm.factory.value];
        console.log(factory);
        const email = this.state.shareFactoryForm.email.value;
        if (email === this.props.uid) {
            alert("Cannot share with yourself!");
            return;
        }
        const usersCurrentlySharedWith = await factory.usersSharedWith(this.props.token);
        if (usersCurrentlySharedWith.filter(shared => shared === email).length > 0) {
            alert("Already shared with that user!");
            return;
        }
        const sharedWith = await factory.shareFactory(this.props.uid, this.props.token, email);
        const updatedShareForm = {
            ...this.state.shareFactoryForm
        }
        const updatedEmail = {
            ...updatedShareForm.email
        };
        updatedEmail.value = "";
        updatedEmail.touched = false;
        updatedEmail.valid = false;
        updatedShareForm.email = updatedEmail;
        const updatedSharedWith = [...this.state.factorySharedWith];
        updatedSharedWith.push(sharedWith);
        this.setState({
            shareFactoryForm: updatedShareForm,
            factorySharedWith: updatedSharedWith
        });
    };

    render() {
        const buildAddFactoryForm = () => {
            const buildNameInput = () => {
                return (
                    <Form.Group>
                        <Form.Label>{this.state.addFactoryForm.name.label}</Form.Label>
                        <Form.Control
                            placeholder={this.state.addFactoryForm.name.label}
                            value={this.state.addFactoryForm.name.value}
                            isValid={this.state.addFactoryForm.name.valid}
                            isInvalid={this.state.addFactoryForm.name.touched && !this.state.addFactoryForm.name.valid}
                            onChange={this.onAddFactoryUpdate}/>
                    </Form.Group>
                );
            };
            const buildSaveButton = () => {
                return (
                    <Button
                        variant={"primary"}
                        type={"submit"}
                        disabled={!this.state.addFactoryValid}>Save</Button>
                );
            };
            return (
                <Form onSubmit={this.onAddFactorySubmit}>
                    {buildNameInput()}
                    {buildSaveButton()}
                </Form>
            );
        };
        const buildFactoriesTable = () => {
            const buildHeading = () => {
                return (
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Owner</th>
                    </tr>
                    </thead>
                );
            };
            const buildBody = () => {
                const buildRows = () => {
                    const buildRow = (id, factory) => {
                        return (
                            <tr key={factory.id}>
                                <td>{factory.name}</td>
                                <td>{factory.userId}</td>
                            </tr>
                        );
                    };
                    return objectUtil.mapObjectToArray(this.state.factories, buildRow);
                };
                return (
                    <tbody>
                    {buildRows()}
                    </tbody>
                );
            };
            return (
                <Table responsive hover striped>
                    {buildHeading()}
                    {buildBody()}
                </Table>
            );
        };
        const buildShareFactory = () => {
            const buildShareFactoryForm = () => {
                const buildFactorySelect = () => {
                    const buildOptions = factories => {
                        const buildOption = factory => {
                            return <option key={factory.id} value={factory.id}>{factory.name}</option>
                        };
                        return factories.map(buildOption);
                    };
                    const factories = objectUtil.mapObjectToArray(this.state.factories, (key, factory) => factory)
                        .filter(factory => factory.userId === this.props.uid);
                    return (
                        <Form.Group>
                            <Form.Label>Select Factory</Form.Label>
                            <Form.Control
                                as={"select"}
                                value={this.state.shareFactoryForm.factory.value}
                                onChange={event => this.onShareFactoryChange(event, "factory")}>
                                {buildOptions(factories)}
                            </Form.Control>
                        </Form.Group>
                    );
                };
                const buildEmailInput = () => {
                    const buildInputGroup = () => {
                        const buildInput = () => {
                            return (
                                <Form.Control
                                    type={"email"}
                                    value={this.state.shareFactoryForm.email.value}
                                    isValid={this.state.shareFactoryForm.email.valid}
                                    isInvalid={this.state.shareFactoryForm.email.touched && !this.state.shareFactoryForm.email.valid}
                                    onChange={event => this.onShareFactoryChange(event, "email")}/>
                            );
                        };
                        const buildShareButton = () => {
                            return (
                                <InputGroup.Append>
                                    <Button
                                        variant={"primary"}
                                        type={"submit"}
                                        disabled={!this.state.shareFactoryFormValid}>Share</Button>
                                </InputGroup.Append>
                            );
                        };
                        return (
                            <InputGroup>
                                {buildInput()}
                                {buildShareButton()}
                            </InputGroup>
                        );
                    };
                    return (
                        <Form.Group>
                            <Form.Label>{this.state.shareFactoryForm.email.label}</Form.Label>
                            {buildInputGroup()}
                        </Form.Group>
                    );
                };
                const buildSharedWith = () => {
                    if (this.state.factorySharedWith.length === 0) return null;
                    const buildSharedWithListGroup = () => {
                        const buildSharedWithListGroupItems = () => {
                            const buildSharedWithListGroupItem = email => {
                                return <ListGroup.Item key={email}>{email}</ListGroup.Item>
                            };
                            return this.state.factorySharedWith.map(buildSharedWithListGroupItem);
                        };
                        return (
                            <ListGroup>
                                {buildSharedWithListGroupItems()}
                            </ListGroup>
                        );
                    };
                    return (
                        <>
                            <h4>Shared With</h4>
                            {buildSharedWithListGroup()}
                        </>
                    );
                };
                return (
                    <Form onSubmit={this.shareFactory}>
                        {buildFactorySelect()}
                        {buildEmailInput()}
                        {buildSharedWith()}
                    </Form>
                );
            };
            const factories = objectUtil.mapObjectToArray(this.state.factories, (key, factory) => factory)
                .filter(factory => factory.userId === this.props.uid)
            if (factories.length === 0) return null;
            return (
                <>
                    <h2>Share Factory</h2>
                    {buildShareFactoryForm()}
                </>
            );
        };
        return (
            <Col>
                <h1>Manage Factories</h1>
                {buildFactoriesTable()}
                <h2>Add Factory</h2>
                {buildAddFactoryForm()}
                {buildShareFactory()}
            </Col>
        );
    }
}

const mapStateToProps= state => {
    return {
        uid: state.auth.uid,
        token: state.auth.token
    }
};

export default connect(mapStateToProps)(ManageFactories);
