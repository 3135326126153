import db from '../axios-instances/firebase-db';

class Building {

    constructor(id, name, powerInput, powerOutput, inputs, outputs) {
        this.id = id;
        this.name = name;
        this.powerInput = powerInput;
        this.powerOutput = powerOutput;
        this.inputs = inputs;
        this.outputs = outputs;
    }

    static addBuilding = async (building) => {
        const response = await db.post('/buildings.json', building);
        return new Building(response.data.name, building.name, building.powerInput, building.powerOutput, building.inputs, building.outputs);
    }

    static loadBuildings = async () => {
        const response = await db.get('/buildings.json');
        const buildings = {};

        for (let key in response.data) {
            const building = response.data[key];
            buildings[key] = new Building(key, building.name, building.powerInput, building.powerOutput, building.inputs, building.outputs);
        }
        return buildings;

    };

    removeFromDatabase = async () => {
        await db.delete(`/buildings/${this.id}.json`);
    };

}

export default Building;
