import React from 'react';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExpandArrowsAlt, faCompressArrowsAlt} from '@fortawesome/free-solid-svg-icons';

import * as actions from '../../store/actions/index';

export const toggleExpandButton = props => (
    <Button
        variant={"secondary"}
        className={["fixed-bottom", "ml-1", "mb-1"].join(" ")}
        onClick={event => props.toggleExpand()}>
        <FontAwesomeIcon icon={props.expand_view ? faCompressArrowsAlt : faExpandArrowsAlt} fixedWidth/>
    </Button>
);

const mapStateToProps = state => {
    return {
        expand_view: state.settings.expand
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpand: () => dispatch(actions.toggle_expand_view())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(toggleExpandButton);
