import moment from 'moment';

import auth from '../../../axios-instances/firebase-auth';

const saveIdToken = (idToken, expiresIn, refreshToken) => {
    localStorage.setItem("token", idToken);
    localStorage.setItem("expiration", moment().add(expiresIn, 's').format());
    localStorage.setItem("refreshToken", refreshToken);
};

export const fetchIdToken = async () => {
    const savedToken = localStorage.getItem('token');
    const expiration = moment(localStorage.getItem('expiration'));
    if (expiration.isAfter(moment())) {
        return savedToken;
    }
    const refreshToken = localStorage.getItem('refreshToken');
    const postData = {
        grant_type: "refresh_token",
        refresh_token: refreshToken
    };
    const response = await auth.post(`/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, postData);
    const responseData = response.data;
    saveIdToken(responseData.id_token, Number(responseData.expires_in), responseData.refresh_token);
    return responseData.id_token;
};

export const fetchEmail = async () => {
    const token = await fetchIdToken();
    const response = await auth.post(`/accounts:lookup?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, {idToken: token});
    const responseData = response.data;
    return {email: responseData.users[0].email, token: token};
};

export const signUp = async (email, password) => {
    const formData = {
        email: email,
        password: password,
        returnSecureToken: true
    };
    const response = await auth.post(`/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, formData);
    const responseData = response.data;
    saveIdToken(responseData.idToken, Number(responseData.expiresIn), responseData.refreshToken);
    return {email: responseData.email, token: responseData.idToken};
};

export const login = async (email, password) => {
    const formData = {
        email: email,
        password: password,
        returnSecureToken: true
    };
    const loginResponse = await auth.post(`/accounts:signInWithPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, formData);
    const loginData = loginResponse.data;
    saveIdToken(loginData.idToken, Number(loginData.expiresIn), loginData.refreshToken);
    return {email: loginData.email, token: loginData.idToken};
}
