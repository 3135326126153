import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router';
import {Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';

import * as actions from './store/actions/index';

import Layout from './hoc/Layout/Layout';
import MaterialList from './containers/MaterialList/MaterialList';
import Buildings from './containers/Buildings/Buildings';
import Productions from './containers/Production/Productions';
import FactoryReport from './containers/FactoryReport/FactoryReport';
import ManageFactories from './containers/ManageFactories/ManageFactories';

import PleaseWait from './components/PleaseWait/PleaseWait';
import SignUp from './containers/Auth/SignUp/SignUp';
import Login from './containers/Auth/Login/Login';
import SignOut from './containers/Auth/SignOut/SignOut';

class App extends Component {

    componentDidMount() {
        this.props.load_auth_data();
    }

    render() {
        const buildRoutes = () => {
            const materialsRoute = () => {
                return this.props.uid.toLowerCase() === 'joserivas823@gmail.com' ?
                    (<Route path={"/materials"} component={MaterialList}/>) :
                    (<Redirect from={"/materials"} to={'/'}/>)
            };
            const buildingsRoute = () => {
                return this.props.uid.toLowerCase() === 'joserivas823@gmail.com' ?
                    (<Route path={"/buildings"} component={Buildings}/>) :
                    (<Redirect from={"/buildings"} to={'/'}/>)
            };
            const loggedInRoutes = (
                <Switch>
                    <Redirect from={"/signup"} to={"/"}/>
                    <Redirect from={"/login"} to={"/"}/>
                    {materialsRoute()}
                    {buildingsRoute()}
                    <Route path={"/productions"} component={Productions}/>
                    <Route path={"/signout"} component={SignOut}/>
                    <Route path={"/manage-factories"} component={ManageFactories}/>
                    <Route exact path={"/"} component={FactoryReport}/>
                </Switch>
            );
            const loggedOutRoutes = (
                <Switch>
                    <Route path={"/signup"} component={SignUp}/>
                    <Route component={Login}/>
                </Switch>
            );
            return this.props.signedIn ? loggedInRoutes : loggedOutRoutes;
        };
        const buildWait = () => (<PleaseWait/>);
        return (
            <div>
                <Layout>
                    <Container className={"mb-5"} fluid={this.props.expand_view}>
                        <Row>
                            {this.props.signingIn ? buildWait() : buildRoutes()}
                        </Row>
                    </Container>
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        signingIn: state.auth.signingIn,
        signedIn: !state.auth.signingIn && state.auth.uid.length > 0,
        expand_view: state.settings.expand,
        uid: state.auth.uid
    };
};

const mapDispatchToProps = dispatch => {
    return {
        load_auth_data: () => dispatch(actions.load_user_data())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
