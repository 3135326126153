export const updateObject = (object, updatedValues) => {
    return {
        ...object,
        ...updatedValues
    }
}

export const mapObjectToArray = (object, mapFunction) => {
    const arr = [];
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            arr.push(mapFunction(key, object[key]));
        }
    }
    return arr;
};
