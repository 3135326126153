import * as actions from '../../actions/actionTypes';

export const start_signing_in = () => {
    return {
        type: actions.AUTH_START_SIGNING_IN
    }
};

export const end_signing_in = () => {
    return {
        type: actions.AUTH_END_SIGNING_IN
    }
};

export const set_email = (email) => {
    return {
        type: actions.AUTH_SET_EMAIL,
        email: email
    }
};

export const clear_error = () => {
    return {
        type: actions.AUTH_CLEAR_ERROR
    }
};

export const set_error = (error) => {
    return {
        type: actions.AUTH_SET_ERROR,
        error: error
    }
}

export const clear_auth_data = () => {
    return {
        type: actions.AUTH_CLEAR_AUTH_DATA
    }
};

export const set_token = (token) => {
    return {
        type: actions.AUTH_SET_TOKEN,
        token: token
    }
};
