export const requiredText = input => input.trim().length > 0;
export const requiredNumber = input => !isNaN(input);
export const minValue = min => (input => Number(input) >= min);
export const isEmail = input => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);
export const notEqual = (value) => (input => value !== input);

export const combineAnd = (a, b) => (input => a(input) && b(input));
export const combineSeveralAnd = (...validators) => {
    return input => {
        if (validators.length === 0) return true;
        let result = true;
        for (let i = 0; i < validators.length && result; i++) {
            result = result && validators[i](input);
        }
        return result;
    };
};
