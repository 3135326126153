import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import {Form, Button, Table} from 'react-bootstrap';

import Product from '../../models/Product';

class MaterialList extends Component {

    state = {
        products: {},
        materialForm: {
            name: "",
            touched: false,
            valid: false
        },
        submitting: false
    };

    componentDidMount() {
        this.loadMaterials();
    }

    loadMaterials = () => {
        Product.loadProducts()
            .then(products => {
                this.setState({products: products});
            })
            .catch(error => console.error(error.response));
    };

    onMaterialNameUpdated = event => {
        const newForm = {
            ...this.state.materialForm
        };

        newForm.name = event.target.value;
        newForm.touched = true;
        newForm.valid = newForm.name.trim().length > 0;

        this.setState({materialForm: newForm});
    };

    onMaterialSubmitted = event => {
        event.preventDefault();

        const materialName = this.state.materialForm.name.trim();

        if (materialName.trim().length === 0) {
            return;
        }

        this.setState({submitting: true});
        Product.addProduct(materialName)
            .then(product => {
                const updatedProducts = {
                    ...this.state.products
                };
                updatedProducts[product.id] = product;
                this.setState({
                    products: updatedProducts,
                    materialForm: {
                        name: "",
                        touched: false,
                        valid: false
                    }
                });
            })
            .catch(error => {
                console.error(error.response ? error.response : error);
            })
            .finally(() => this.setState({submitting: false}));

    };

    onDeleteProduct = (product) => {
        product.removeFromDatabase()
            .then(() => this.loadMaterials());
    };

    render() {

        const productList = [];
        for (let key in this.state.products) {
            const product = this.state.products[key];
            productList.push((
                <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>
                        <Button
                            type={"button"}
                            onClick={() => this.onDeleteProduct(product)}
                            variant={"danger"}>
                            X
                        </Button>
                    </td>
                </tr>
            ));
        }

        return (
            <Col>
                <h1>Materials</h1>
                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>Material</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productList}
                    </tbody>
                </Table>
                <h2>Add Material</h2>
                <Form inline onSubmit={this.onMaterialSubmitted}>
                    <Form.Control
                        className={"mr-3"}
                        placeholder={"Material Name"}
                        value={this.state.materialForm.name}
                        isValid={this.state.materialForm.valid}
                        isInvalid={this.state.materialForm.touched && !this.state.materialForm.valid}
                        onChange={this.onMaterialNameUpdated}/>
                    <Button
                        type={"submit"}
                        disabled={this.state.submitting || !this.state.materialForm.valid}>Submit</Button>
                </Form>
            </Col>
        );
    }
}

export default MaterialList;
