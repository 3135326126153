import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {connect} from 'react-redux';

import AccountDropdown from './AccountDropdown/AccountDropdown';

const buildLinks = (uid) => {
    let links = [
        {path: "/productions", name: "Productions"}
    ];
    if (uid.toLowerCase() === 'joserivas823@gmail.com') {
        links = [
            {path: "/materials", name: "Materials"},
            {path: "/buildings", name: "Buildings"},
            ...links
        ];
    }
    return links.map(link => <LinkContainer key={link.path} to={link.path}><Nav.Link>{link.name}</Nav.Link></LinkContainer>);
};

const navigation = props => (
    <Navbar bg={"light"} expand={"md"} className={"mb-2"}>
        <LinkContainer to={"/"}><Navbar.Brand>Satisfactory Planner</Navbar.Brand></LinkContainer>
        <Navbar.Toggle/>
        <Navbar.Collapse>
            <Nav className={"mr-auto"}>
                {buildLinks(props.email)}
            </Nav>
            {props.isSignedIn ? (<AccountDropdown email={props.email}/>) : null}
        </Navbar.Collapse>
    </Navbar>
);

const mapStateToProps = state => {
    return {
        isSignedIn: !state.auth.signingIn && state.auth.uid.length > 0,
        email: state.auth.uid
    };
};

export default connect(mapStateToProps)(navigation);
