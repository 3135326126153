import * as actionTypes from '../../actions/actionTypes';
import * as util from '../../../util/objectUtil';

const initialState = {
    uid: '',
    error: null,
    signingIn: true,
    token: ''
};

const start_signing_in = (state, action) => {
    return util.updateObject(state, {
        ...initialState,
    });
};

const end_signing_in = (state, action) => {
    return util.updateObject(state, {signingIn: false});
};

const set_email = (state, action) => {
    return util.updateObject(state, {uid: action.email});
};

const clear_error = (state, action) => {
    return util.updateObject(state, {error: null});
};

const set_error = (state, action) => {
    return util.updateObject(state, {error: action.error});
};

const clear_data = (state, action) => {
    return util.updateObject(state, {
        uid: '',
        error: null,
        signingIn: false,
        token: ''
    });
};

const set_token = (state, action) => {
    return util.updateObject(state, {token: action.token});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START_SIGNING_IN:
            return start_signing_in(state, action);
        case actionTypes.AUTH_END_SIGNING_IN:
            return end_signing_in(state, action);
        case actionTypes.AUTH_SET_EMAIL:
            return set_email(state, action);
        case actionTypes.AUTH_CLEAR_ERROR:
            return clear_error(state, action);
        case actionTypes.AUTH_SET_ERROR:
            return set_error(state, action);
        case actionTypes.AUTH_CLEAR_AUTH_DATA:
            return clear_data(state, action);
        case actionTypes.AUTH_SET_TOKEN:
            return set_token(state, action);
        default:
            return state;
    }
};

export default reducer;
