import React from 'react';
import {Nav, NavDropdown} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

const accountDropdown = props => {
    return (
        <Nav>
            <NavDropdown id={"account-dropdown"} title={`Signed in as ${props.email}`} alignRight>
                <LinkContainer to={"/manage-factories"}>
                    <NavDropdown.Item>Manage Factories</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider/>
                <LinkContainer to={"/signout"}>
                    <NavDropdown.Item>Sign Out</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    );
};

export default accountDropdown;
