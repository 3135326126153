import db from '../axios-instances/firebase-db';

class Product {

    constructor(id, name) {
        this.id = id;
        this.name = name;
    }

    static addProduct = async (name) => {
        const product = await db.post('/products.json', {name: name});
        return new Product(product.data.name, name);
    };

    static loadProducts = async () => {
        const response = await db.get('/products.json');
        const products = {};

        for (let key in response.data) {
            products[key] = new Product(key, response.data[key].name);
        }

        return products;

    };

    removeFromDatabase = async () => {
        const response = await db.delete(`/products/${this.id}.json`)
        return response;
    }

}

export default Product;
