import React, {Component} from 'react';
import {Row, Col, Form, InputGroup, Button, Table} from 'react-bootstrap';

import * as filters from '../../util/filters';
import * as validators from '../../util/validators';
import Building from '../../models/Building';

class Buildings extends Component {

    state = {
        buildingForm: {
            name: {
                label: "Building Name",
                value: "",
                touched: false,
                valid: false,
                validator: validators.requiredText
            },
            powerInput: {
                label: "Power Input",
                value: 0,
                touched: false,
                valid: true,
                filter: filters.filterNumber,
                validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
            },
            powerOutput: {
                label: "Power Output",
                value: 0,
                touched: false,
                valid: true,
                filter: filters.filterNumber,
                validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
            },
            productInputs: {
                label: "Product Inputs",
                value: 0,
                touched: false,
                valid: true,
                filter: filters.filterNumber,
                validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
            },
            productOutputs: {
                label: "Product Outputs",
                value: 0,
                touched: false,
                valid: true,
                filter: filters.filterNumber,
                validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
            }
        },
        formValid: false,
        buildings: {}
    }

    componentDidMount() {
        this.loadBuildings();
    }

    loadBuildings = () => {
        Building.loadBuildings()
            .then(buildings => {
                this.setState({buildings: buildings});
            })
            .catch(error => {
                console.error(error);
            });
    };

    onValueChanged = (event, key) => {
        const updatedForm = {
            ...this.state.buildingForm
        };
        const updatedElement = {
            ...updatedForm[key]
        };
        updatedElement.value = updatedElement.filter ? updatedElement.filter(event.target.value) : event.target.value;

        updatedElement.touched = true;
        updatedElement.valid = updatedElement.validator ? updatedElement.validator(updatedElement.value) : true;

        updatedForm[key] = updatedElement;

        let formValid = true;
        for (let key in updatedForm) {
            formValid = formValid && updatedForm[key].valid;
        }
        this.setState({buildingForm: updatedForm, formValid: formValid});

    };

    submitForm = event => {
        event.preventDefault();

        if (!this.state.formValid) {
            return;
        }

        const building = {
            name: this.state.buildingForm.name.value.trim(),
            powerInput: this.state.buildingForm.powerInput.value,
            powerOutput: this.state.buildingForm.powerOutput.value,
            inputs: this.state.buildingForm.productInputs.value,
            outputs: this.state.buildingForm.productOutputs.value
        };

        Building.addBuilding(building)
            .then(building => {
                const updatedBuildings = {
                    ...this.state.buildings
                }
                updatedBuildings[building.id] = building;
                this.setState({
                    buildingForm: {
                        name: {
                            label: "Building Name",
                            value: "",
                            touched: false,
                            valid: false,
                            validator: validators.requiredText
                        },
                        powerInput: {
                            label: "Power Input",
                            value: 0,
                            touched: false,
                            valid: true,
                            filter: filters.filterNumber,
                            validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
                        },
                        powerOutput: {
                            label: "Power Output",
                            value: 0,
                            touched: false,
                            valid: true,
                            filter: filters.filterNumber,
                            validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
                        },
                        productInputs: {
                            label: "Product Inputs",
                            value: 0,
                            touched: false,
                            valid: true,
                            filter: filters.filterNumber,
                            validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
                        },
                        productOutputs: {
                            label: "Product Outputs",
                            value: 0,
                            touched: false,
                            valid: true,
                            filter: filters.filterNumber,
                            validator: validators.combineAnd(validators.requiredNumber, validators.minValue(0))
                        }
                    },
                    formValid: false,
                    buildings: updatedBuildings
                });
            })
            .catch(error => {
                console.error(error.response);
            });

    }

    onDeleteBuilding = (building) => {
        building.removeFromDatabase()
            .then(() => this.loadBuildings());
    };

    render() {

        let buildings = [];
        for (let key in this.state.buildings) {
            const building = this.state.buildings[key];
            buildings.push((
                <tr key={building.id}>
                    <td>{building.name}</td>
                    <td>{building.powerInput}MW</td>
                    <td>{building.powerOutput}MW</td>
                    <td>{building.inputs}</td>
                    <td>{building.outputs}</td>
                    <td>
                        <Button
                        variant={"danger"}
                        type={"button"}
                        onClick={() => this.onDeleteBuilding(building)}>X</Button>
                    </td>
                </tr>
            ));
        }

        return (
            <Col>
                <h1>Buildings</h1>
                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Power Input</th>
                        <th>Power Output</th>
                        <th>Inputs</th>
                        <th>Outputs</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {buildings}
                    </tbody>
                </Table>
                <h2>Add Building</h2>
                <Form onSubmit={this.submitForm}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{this.state.buildingForm.name.label}</Form.Label>
                                <Form.Control
                                    value={this.state.buildingForm.name.value}
                                    placeholder={this.state.buildingForm.name.label}
                                    onChange={event => this.onValueChanged(event, "name")}
                                    isValid={this.state.buildingForm.name.valid}
                                    isInvalid={this.state.buildingForm.name.touched && !this.state.buildingForm.name.valid}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{this.state.buildingForm.powerInput.label}</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={"number"}
                                        value={this.state.buildingForm.powerInput.value}
                                        placeholder={this.state.buildingForm.powerInput.label}
                                        onChange={event => this.onValueChanged(event, "powerInput")}
                                        isValid={this.state.buildingForm.powerInput.valid}
                                        isInvalid={this.state.buildingForm.powerInput.touched && !this.state.buildingForm.powerInput.valid}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>MW</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>{this.state.buildingForm.powerOutput.label}</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={"number"}
                                        value={this.state.buildingForm.powerOutput.value}
                                        placeholder={this.state.buildingForm.powerOutput.label}
                                        onChange={event => this.onValueChanged(event, "powerOutput")}
                                        isValid={this.state.buildingForm.powerOutput.valid}
                                        isInvalid={this.state.buildingForm.powerOutput.touched && !this.state.buildingForm.powerOutput.valid}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text>MW</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{this.state.buildingForm.productInputs.label}</Form.Label>
                                <Form.Control
                                    type={"number"}
                                    value={this.state.buildingForm.productInputs.value}
                                    placeholder={this.state.buildingForm.productInputs.label}
                                    onChange={event => this.onValueChanged(event, "productInputs")}
                                    isValid={this.state.buildingForm.productInputs.valid}
                                    isInvalid={this.state.buildingForm.productInputs.touched && !this.state.buildingForm.productInputs.valid}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>{this.state.buildingForm.productOutputs.label}</Form.Label>
                                <Form.Control
                                    type={"number"}
                                    value={this.state.buildingForm.productOutputs.value}
                                    placeholder={this.state.buildingForm.productOutputs.label}
                                    onChange={event => this.onValueChanged(event, "productOutputs")}
                                    isValid={this.state.buildingForm.productOutputs.valid}
                                    isInvalid={this.state.buildingForm.productOutputs.touched && !this.state.buildingForm.productOutputs.valid}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type={'submit'} disabled={!this.state.formValid}>Submit</Button>
                </Form>
            </Col>
        );
    }
}

export default Buildings;
