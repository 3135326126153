import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../store/actions/index';

class SignOut extends Component {

    componentDidMount() {
        this.props.signOut();
        this.props.history.goBack();
    }

    render() {
        return (<>t</>);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch(actions.signOut())
    };
};

export default connect(null, mapDispatchToProps)(SignOut);
