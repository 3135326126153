import * as actionTypes from '../../actions/actionTypes';
import * as objectUtil from '../../../util/objectUtil';

const initialState = {
    expand: false
};

const settings_toggle_expand = (state, action) => {
    const newExpand = !state.expand;
    return objectUtil.updateObject(state, {expand: newExpand});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETTINGS_TOGGLE_EXPAND:
            return settings_toggle_expand(state, action);
        default:
            return state;
    }
};

export default reducer;
