import React, {Component} from 'react';
import {Alert, Button, Card, Col, Form, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {connect} from 'react-redux';

import * as inputTypes from '../../../util/inputTypes';
import * as validators from '../../../util/validators';
import * as filters from '../../../util/filters';
import * as forms from '../../../util/forms';

import * as actions from '../../../store/actions/index';

class Login extends Component {
    state = {
        form: {
            email: {
                label: "Email",
                type: inputTypes.EMAIL,
                value: "",
                touched: false,
                valid: false,
                validator: validators.combineAnd(validators.requiredText, validators.isEmail),
                filter: filters.trimText
            },
            password:  {
                label: "Password",
                type: inputTypes.PASSWORD,
                value: "",
                touched: false,
                valid: false,
                validator: validators.requiredText
            },
        },
        formValid: false
    };

    componentDidMount() {
        this.props.clearError();
    }

    onFormInputChange = (event, key) => {
        const updatedForm = forms.updateForm(event, key, this.state.form);
        this.setState(updatedForm);
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        if(!this.state.formValid) return;
        this.props.login(this.state.form.email.value, this.state.form.password.value);
    }

    render() {
        const buildForm = () => {
            const buildFormInputs = () => {
                const buildFormInput = (formElement) => {
                    const buildTextInput = () => {
                        return (
                            <Form.Group key={formElement.id}>
                                <Form.Label>{formElement.label}</Form.Label>
                                <Form.Control
                                    type={formElement.type}
                                    value={formElement.value}
                                    placeholder={formElement.label}
                                    isValid={formElement.valid}
                                    isInvalid={formElement.touched && !formElement.valid}
                                    onChange={event => this.onFormInputChange(event, formElement.id)}/>
                            </Form.Group>
                        );
                    };
                    switch (formElement.type) {
                        case inputTypes.EMAIL:
                        case inputTypes.PASSWORD:
                            return buildTextInput();
                        default:
                            return null;
                    }
                };
                const formInputs = [];
                for (let key in this.state.form) {
                    if (this.state.form[key]) {
                        formInputs.push(buildFormInput({id: key, ...this.state.form[key]}))
                    }
                }
                return formInputs;
            };
            const buildButtonsRow = () => {
                const buildLoginButton = () => {
                    return (
                        <Col>
                            <LinkContainer to={"/signup"}>
                                <Button type={"button"} variant={"warning"}>Create Account</Button>
                            </LinkContainer>
                        </Col>
                    );
                };
                const buildSignUpButton = () => {
                    return (
                        <Col>
                            <Button type={"submit"} disabled={!this.state.formValid}>
                                Login
                            </Button>
                        </Col>
                    );
                };
                return (
                    <Row>
                        {buildSignUpButton()}
                        {buildLoginButton()}
                    </Row>
                );
            };
            return (
                <Form onSubmit={this.onFormSubmit}>
                    {buildFormInputs()}
                    {buildButtonsRow()}
                </Form>
            );
        };
        const buildError = () => {
            if (!this.props.error) return;
            return (
                <Alert variant={"danger"} className={"mt-3"}>
                    <p>{this.props.error}</p>
                </Alert>
            );
        };
        return (
            <Col xs={12} sm={{span: 8, offset: 2}}>
                <Card className={"p-3"}>
                    {buildForm()}
                    {buildError()}
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.error
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearError: () => dispatch(actions.clear_error()),
        login: (email, password) => dispatch(actions.login(email, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
