import * as actionCreators from './authActionCreators';
import * as firebaseUser from './firebaseUser';
import {clear_auth_data} from './authActionCreators';
import moment from 'moment';

export {clear_error} from './authActionCreators';

const tokenExists = () => {
    const token = localStorage.getItem('token');
    return !!token;
}

const clearLocalStorage = () => {
    localStorage.clear();
};

const auto_renew_token = () => {
    return async dispatch => {
        const getMillis = (timeout) => {
            return new Promise((resolve => {
                setTimeout(() => {
                    const expiration = moment(localStorage.getItem('expiration'));
                    const now = moment();
                    const millis = Math.abs(expiration.diff(now, 'seconds')) * 1000;
                    resolve(millis);
                }, timeout)
            }));
        };
        let millis = 0;
        let numTries = 0;
        while (numTries < 10 && millis === 0) {
            millis = await getMillis(500);
            numTries++;
        }
        setTimeout(async () => {
            const token = await firebaseUser.fetchIdToken();
            dispatch(actionCreators.set_token(token));
            dispatch(auto_renew_token());
        }, millis);
    };
};

export const load_user_data = () => {
    return async dispatch => {
        const load_email_from_firebase = async () => {
            const userData = await firebaseUser.fetchEmail();
            dispatch(actionCreators.set_email(userData.email));
            dispatch(actionCreators.set_token(userData.token));
            dispatch(actionCreators.end_signing_in());
            dispatch(auto_renew_token());
        };
        dispatch(actionCreators.start_signing_in());
        if (tokenExists()) {
            await load_email_from_firebase();
        } else {
            dispatch(actionCreators.end_signing_in());
        }
    }
};

export const signUp = (email, password) => {
    return async dispatch => {
        dispatch(actionCreators.clear_error());
        clearLocalStorage();
        try {
            const userData = await firebaseUser.signUp(email, password);
            dispatch(actionCreators.set_token(userData.token));
            dispatch(actionCreators.set_email(userData.email));
            dispatch(auto_renew_token());
        } catch (error) {
            let message = "There was an error signing up, please try again";
            if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
                switch (error.response.data.error.message) {
                    case "EMAIL_EXISTS":
                        message = "An account with that email already exists, please log in.";
                        break;
                    default:
                        break;
                }
            }
            dispatch(actionCreators.set_error(message));
        }
    };
};

export const login = (email, password) => {
    return async dispatch => {
        dispatch(actionCreators.clear_error());
        clearLocalStorage();
        try {
            const loginData = await firebaseUser.login(email, password);
            dispatch(actionCreators.set_token(loginData.token));
            dispatch(actionCreators.set_email(loginData.email));
            dispatch(auto_renew_token());
        } catch (error) {
            let message = "There was an error logging in, please try again";
            if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
                switch (error.response.data.error.message) {
                    case "EMAIL_NOT_FOUND":
                        message = "No account with that email was found, please create an account.";
                        break;
                    case "INVALID_PASSWORD":
                        message = "Incorrect password, please try again."
                        break;
                    case "USER_DISABLED":
                        message = "This account has been disabled";
                        break;
                    default:
                        break;
                }
                dispatch(actionCreators.set_error(message));
            }
        }
    };
};

export const signOut = () => {
    return dispatch => {
        clearLocalStorage();
        dispatch(clear_auth_data());
    };
};
