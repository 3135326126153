import React, {Component} from 'react';
import {Row, Col, Table, Button, Form} from 'react-bootstrap';
import {connect} from 'react-redux';

import Product from '../../models/Product';
import Building from '../../models/Building';
import Production from '../../models/Production';
import Factory from '../../models/Factory';
import * as objectUtil from '../../util/objectUtil';

class FactoryReport extends Component {

    state = {
        loading: true,
        products: {},
        buildings: {},
        productions: {},
        totalInput: {},
        totalOutput: {},
        totalPowerInput: 0,
        totalPowerOutput: 0,
        hideBuilt: true,
        groupByOutput: false,
        factories: {},
        selectedFactory: ""
    }

    componentDidMount() {
        this.initialLoad().then();
    }

    initialLoad = async () => {
        const factories = await Factory.loadFactories(this.props.uid, this.props.token);
        const factoryIds = Object.keys(factories);
        if (factoryIds.length === 0) {
            this.props.history.push('/manage-factories');
            return;
        }
        this.setState({
            factories: factories,
            selectedFactory: factoryIds[0]
        }, async () => await this.loadReport());
    };

    loadReport = async () => {
        const calculateTotals = (products, buildings, productions) => {
            const totalInput = {};
            const totalOutput = {};
            let totalPowerInput = 0;
            let totalPowerOutput = 0;
            for (let key in productions) {
                const production = productions[key];
                const building = buildings[production.building_id];
                for (let index in production.inputs) {
                    const input = production.inputs[index];
                    if (totalInput[input.product]) {
                        totalInput[input.product] += input.amount;
                    } else {
                        totalInput[input.product] = input.amount;
                    }
                }
                for (let index in production.outputs) {
                    const output = production.outputs[index];
                    if (totalOutput[output.product]) {
                        totalOutput[output.product] += output.amount;
                    } else {
                        totalOutput[output.product] = output.amount;
                    }
                }
                totalPowerInput += building.powerInput;
                totalPowerOutput += building.powerOutput;
            }
            this.setState({
                loading: false,
                products: products,
                buildings: buildings,
                productions: productions,
                totalInput: totalInput,
                totalOutput: totalOutput,
                totalPowerInput: totalPowerInput,
                totalPowerOutput: totalPowerOutput
            });
        };

        const products = await Product.loadProducts();
        const buildings = await Building.loadBuildings();
        const productions = await Production.loadProductions(this.state.selectedFactory, this.props.token);
        calculateTotals(products, buildings, productions);
    }

    productionsAsArray = () => {
        const productions = [];
        for (let key in this.state.productions) {
            productions.push(this.state.productions[key]);
        }
        return productions;
    };

    onChangeFactory = async event => {
        this.setState({
            selectedFactory: event.target.value
        }, async () => await this.loadReport());
    };

    render() {
        const buildReport = () => {
            if (this.state.loading) return (<h2>Loading</h2>);
            const buildControlRow = () => {
                const buildHideBuiltButton = () => {
                    return (
                        <Col className={"text-center"}>
                            <Button
                                variant={this.state.hideBuilt ? "success" : "danger"}
                                onClick={() => this.setState(oldState => ({hideBuilt: !oldState.hideBuilt}))}
                                type={"button"}>
                                {this.state.hideBuilt ? "Show Built" : "Hide Built"}
                            </Button>
                        </Col>
                    );
                };
                const buildGroupByButton = () => {
                    return (
                        <Col className={"text-center"}>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState(oldState => ({groupByOutput: !oldState.groupByOutput}))}
                                type={"button"}>
                                {this.state.groupByOutput ? "Default Grouping" : "Group By Output"}
                            </Button>
                        </Col>
                    )
                };
                const buildRefreshButton = () => {
                    return (
                        <Col className={"text-center"}>
                            <Button
                                type={"button"}
                                variant={"primary"}
                                onClick={() => {
                                    this.loadReport()
                                }}>
                                Refresh
                            </Button>
                        </Col>
                    );
                };
                return (
                    <Row className={"mb-2"}>
                        {buildHideBuiltButton()}
                        {buildGroupByButton()}
                        {buildRefreshButton()}
                    </Row>
                );
            };
            const buildProductionsReport = () => {
                const buildProductionTable = () => {
                    const buildHeading = () => {
                        return (
                            <thead>
                            <tr>
                                <th>Building</th>
                                <th>Power Usage</th>
                                <th>Power Output</th>
                                <th>Inputs</th>
                                <th>Outputs</th>
                            </tr>
                            </thead>
                        );
                    };
                    const buildBody = () => {
                        const buildProductionRows = () => {
                            const buildProductionRow = production => {
                                const shouldHide = () => this.state.hideBuilt && production.isBuilt;
                                if (shouldHide()) return null;
                                const formatProductionProducts = productionProducts => {
                                    const formatProductionProduct = productionProduct => {
                                        const product = this.state.products[productionProduct.product];
                                        return `${product.name}:${productionProduct.amount}/min`;
                                    };
                                    return productionProducts.map(formatProductionProduct).join(' + ');
                                };
                                const building = this.state.buildings[production.building_id];
                                const className = "table-" + (production.isBuilt ? "success" : "danger");
                                return ((
                                    <tr key={production.id} className={className}>
                                        <td>{building.name}</td>
                                        <td>{building.powerInput}MW</td>
                                        <td>{building.powerOutput}MW</td>
                                        <td>{formatProductionProducts(production.inputs)}</td>
                                        <td>{formatProductionProducts(production.outputs)}</td>
                                    </tr>
                                ));
                            };
                            const buildProductionRowsDefaultOrder = () => {
                                return this.productionsAsArray().map(buildProductionRow);
                            };
                            const buildProductionRowsGroupByOutput = () => {
                                const productOutputComparator = (product1, product2) => {
                                    if (product1.outputs.length !== product2.outputs.length) {
                                        return product1.outputs.length - product2.outputs.length
                                    }
                                    let i = 0;
                                    let foundMismatch = false;
                                    while (i < product1.outputs.length && !foundMismatch) {
                                        if (product1.outputs[i].product !== product2.outputs[i].product) {
                                            foundMismatch = true;
                                        } else {
                                            i++;
                                        }
                                    }
                                    if (!foundMismatch) {
                                        return 0;
                                    } else if (product1.outputs[i].product > product2.outputs[i].product) {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                };
                                return this.productionsAsArray()
                                    .sort(productOutputComparator)
                                    .map(buildProductionRow);
                            };

                            return this.state.groupByOutput ? buildProductionRowsGroupByOutput() : buildProductionRowsDefaultOrder();
                        };
                        return (
                            <tbody>
                            {buildProductionRows()}
                            </tbody>
                        );
                    };
                    return (
                        <Table responsive striped hover bordered>
                            {buildHeading()}
                            {buildBody()}
                        </Table>
                    );
                };
                return (
                    <>
                        <h2>Productions</h2>
                        {buildProductionTable()}
                    </>
                );
            };
            const buildInputOutputReport = () => {
                const buildProductTableFromTotals = (totalProductList, totalPower) => {
                    const buildProductTableHeading = () => {
                        return (
                            <thead>
                            <tr>
                                <th>Material</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                        );
                    };
                    const buildProductTableBody = () => {
                        const buildOutputProductRows = () => {
                            const buildProductAmountRow = (key, productName, amount) => {
                                return (
                                    <tr key={key}>
                                        <td>{productName}</td>
                                        <td>{amount}/min</td>
                                    </tr>
                                );
                            };
                            const outputProductRows = [];
                            for (let key in totalProductList) {
                                const amount = totalProductList[key];
                                const product = this.state.products[key];
                                outputProductRows.push(buildProductAmountRow(key, product.name, amount));
                            }
                            return outputProductRows;
                        };
                        const buildPowerOutputRow = () => {
                            if (totalPower <= 0) return null;
                            return (
                                <tr>
                                    <td><strong>Power</strong></td>
                                    <td>{totalPower}MW</td>
                                </tr>
                            );
                        };
                        return (
                            <tbody>
                            {buildOutputProductRows()}
                            {buildPowerOutputRow()}
                            </tbody>
                        );
                    };
                    return (
                        <Table responsive striped hover bordered>
                            {buildProductTableHeading()}
                            {buildProductTableBody()}
                        </Table>
                    );
                };
                const buildInputReport = () => {
                    return (
                        <>
                            <h2>Total Input</h2>
                            {buildProductTableFromTotals(this.state.totalInput, this.state.totalPowerInput)}
                        </>
                    );
                };
                const buildOutputReport = () => {
                    return (
                        <>
                            <h2>Total Output</h2>
                            {buildProductTableFromTotals(this.state.totalOutput, this.state.totalPowerOutput)}
                        </>
                    );
                };
                return (
                    <>
                        {buildInputReport()}
                        {buildOutputReport()}
                    </>
                );
            };
            const buildSurplusDeficitReport = () => {
                const buildProductTableWithInputOutputPredicate = inputOutputPredicate => {
                    const buildProductTableHeading = () => {
                        return (
                            <thead>
                            <tr>
                                <th>Material</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                        );
                    };
                    const buildProductTableBody = () => {
                        const buildProductRows = () => {
                            const productRows = [];
                            for (let key in this.state.products) {
                                const product = this.state.products[key];
                                const input = this.state.totalInput[product.id] ? this.state.totalInput[product.id] : 0;
                                const output = this.state.totalOutput[product.id] ? this.state.totalOutput[product.id] : 0;
                                if (inputOutputPredicate(input, output)) {
                                    productRows.push((
                                        <tr key={key}>
                                            <td>{product.name}</td>
                                            <td>{Math.abs(input - output)}/min</td>
                                        </tr>
                                    ));
                                }
                            }
                            return productRows;
                        };
                        const buildPowerRow = () => {
                            if (!inputOutputPredicate(this.state.totalPowerInput, this.state.totalPowerOutput)) return null;
                            return (
                                <tr>
                                    <td><strong>Power</strong></td>
                                    <td>{Math.abs(this.state.totalPowerInput - this.state.totalPowerOutput)}MW</td>
                                </tr>
                            );
                        };
                        return (
                            <tbody>
                            {buildProductRows()}
                            {buildPowerRow()}
                            </tbody>
                        );
                    };
                    return (
                        <Table responsive striped hover bordered>
                            {buildProductTableHeading()}
                            {buildProductTableBody()}
                        </Table>
                    );
                };
                const buildSurplusReport = () => {
                    const isSurplus = (input, output) => input < output;
                    return (
                        <>
                            <h2>Total Surplus</h2>
                            {buildProductTableWithInputOutputPredicate(isSurplus)}
                        </>
                    );
                };
                const buildDeficitReport = () => {
                    const isDeficit = (input, output) => input > output;
                    return (
                        <>
                            <h2>Total Deficit</h2>
                            {buildProductTableWithInputOutputPredicate(isDeficit)}
                        </>
                    );
                };
                return (
                    <>
                        {buildSurplusReport()}
                        {buildDeficitReport()}
                    </>
                );
            };
            return (
                <>
                    {buildControlRow()}
                    {buildProductionsReport()}
                    {buildInputOutputReport()}
                    {buildSurplusDeficitReport()}
                </>
            );
        };
        const buildFactorySelect = () => {
            const buildSelect = () => {
                const buildOptions = () => {
                    const buildOption = (key, factory) => {
                        return <option key={key} value={key}>{factory.name}</option>
                    };
                    return objectUtil.mapObjectToArray(this.state.factories, buildOption);
                };
                return (
                    <Form.Control
                        as={"select"}
                        value={this.state.selectedFactory}
                        onChange={this.onChangeFactory}>
                        {buildOptions()}
                    </Form.Control>
                );
            };
            return (
                <Form.Group>
                    <Form.Label>Select Factory</Form.Label>
                    {buildSelect()}
                </Form.Group>
            );
        };
        return (
            <Col>
                <h1>Factory Report</h1>
                {buildFactorySelect()}
                {buildReport()}
            </Col>
        );
    }
}

const mapStateToProps = state => {
    return {
        uid: state.auth.uid,
        token: state.auth.token
    };
};

export default connect(mapStateToProps)(FactoryReport);
