export const filterNumber = input => isNaN(Number(input)) ? input : Number(input);
export const trimText = input => input.trim();
export const lowerCaseText = input => input.toLowerCase();
export const removeSpaces = input => input.replace(/\s/g, '');

export const combineFilters = (...filters) => {
    return input => {
        if (filters.length === 0) return input;
        let result = input;
        for (let i  = 0; i < filters.length; i++) {
            result = filters[i](result);
        }
        return result;
    };
}
